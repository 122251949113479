.welcomeCards {
  text-decoration: none !important;
  text-decoration-color: black;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 22%);
  color: black;
}

/* .welcomeCards :hover {
  cursor: pointer;
  color: black;
  text-decoration-color: black !important;
} */
.home-card-header {
  color: gray;
  font-weight: bold;
  font-size: x-large;
  /* position: absolute;
  top: 0;
  left: 0; */
}
.home-card-links {
  /* display: flex; */
  /* flex-direction: column; */
  /* row-gap: 10px; */
  /* column-gap: 10px; */
  /* margin-top: 20px; */
  /* justify-content: center; */
}
.home-card-links button {
  color: white;
  background-color: #124069;
  /* border-radius: 15px; */
}

.home-card-links button:hover {
  color: white;
  background-color: #124069;
  box-shadow: 4px 0px 4px 0px rgb(0 0 0 / 22%);
}
