.flash {
  /* top: 50px;
  right: 10px;
  display: flex;
  justify-content: center; */
  color: white;
  font-weight: bold;
  text-align: center;
}
.flash-success div {
  color: white;
  text-align: center;
  background-color: green !important;
}
.flash-error div {
  color: white;
  text-align: center;
  background-color: var(--failed-status, red) !important;
}
.flash-info div {
  color: white;
  text-align: center;
  background-color: #0080a3 !important;
}
.flash-loading div {
  color: white;
  text-align: center;
  background-color: gray !important;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: white;
}

.e-spin-material {
  height: 20px !important;
  width: 20px !important;
  transform-origin: 10px 10px 10px !important;
}

.e-toast-container .e-toast .e-toast-message .e-toast-content {
}
