.att-badge {
  /* text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  font-weight: bold;
  background-color: #3d3e3f;
  font-size: medium;
  color: white;
  padding: 5px 15px; */
  width: auto;
  display: flex;
}

.e-badge {
  color: white;
  font-size: medium;
}

.e-label-top {
  color: var(--att-primary, #124069) !important;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
  color: black !important;
}

.e-ddl.e-input-group.e-control-wrapper.e-input-focus::before,
.e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
  background: var(--att-primary, darkblue) !important;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: var(--att-primary, darkblue) !important;
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
  color: var(--att-primary, darkblue) !important;
}

e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-bottom-color: var(--att-primary, darkblue) !important;
}

/* .e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper :focus {
  color: var(--att-primary, #124069) !important;
} */

.e-input-focus {
  border-bottom-color: var(--att-primary, #124069) !important;
}

.e-outline {
  border-bottom-color: var(--att-primary, #124069) !important;
}

.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-content {
  padding: 10px;
}

.run-tests-wrapper {
  width: var(--page-content-width, 80%);
  justify-content: center;
}

.run-test-btn {
  background-color: var(--passed-status) !important;
}

.run-test-btn-disabled {
  background-color: var(--att-secondary) !important;
}

.save-edit-btn {
  background-color: var(--att-primary) !important;
}

.tests-execution-name-textbox {
  color: var(--att-primary, #124069);
}

.tests-execution-description-textbox {
  color: var(--att-primary, #124069);
}

.urls-section {
  margin-bottom: var(--margin-between-panels);
}

.rt-filters-icon {
  font-size: 22px;
  color: var(--att-primary);
  cursor: pointer;
  /*float: right;*/
}

.rt-filters-panel {
  width: 100% !important;
  margin-bottom: var(--margin-between-panels);
}

.rt-header-row {
  margin-bottom: var(--margin-between-panels);
}

.rt-header-row>div {
  margin-bottom: var(--margin-between-cols);
}

.rt-header-row .e-input-group {
  margin-bottom: 0 !important;
}

.selected-urls-container {
  border-left-color: var(--att-secondary);
  border-left-width: thin;
  border-left-style: solid;
}

.no-selected-urls {
  font-style: italic;
  font-size: medium;
  color: var(--att-secondary);
}

.selected-urls-list>li {
  margin-bottom: 5px;
}

.selected-urls-list>li>a {
  font-size: 1em;
}

.rt-sidebar-menu.e-menu-wrapper ul .e-menu-item {
  width: var(--sidebar-width, 160px);
  background-color: var(--att-primary, darkblue) !important;
  color: white !important;
  font-size: medium;
}

.rt-sidebar-menu.e-menu-wrapper ul .e-menu-item:hover {
  width: var(--sidebar-width, 160px);
  background-color: var(--att-secondary, gray) !important;
  color: white !important;
  font-size: medium;
}

.tree-buttons {
  display: flex;
  column-gap: 10px;
}

.selectedUrl {
  font-size: small;
  font-style: italic;
}

.control-section {
  display: flex;
  column-gap: 10px;
}

.container-description {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sidebar {
  background-color: var(--att-primary, #124069);
}