.text-name {
  background-color: 'white';
  color: black;
  display: 'flex';
  font-weight: bolder;
  font-size: 1rem;
}

.text-description {
  background-color: 'white';
  color: black;
  display: 'flex';
  font-weight: 500;
  font-size: 0.9rem;
  padding-left: 0.1rem;
}

.text-content {
  max-width: '300px';
  cursor: 'pointer';
  color: black;
}
