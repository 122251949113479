.e-sidebar {
  height: available !important;
}

.e-sidebar-context {
  min-height: 100vh;
}

.sidebar-menu.e-menu-wrapper {
  width: var(--sidebar-width, 160px);
  background-color: var(--att-primary);
  height: available;
}

.sidebar-menu.e-menu-wrapper .e-menu-item {
  color: white !important;
  background-color: var(--att-primary);
  width: var(--sidebar-width, 160px);
  font-size: 14px;
}

.sidebar-menu.e-menu-wrapper .e-menu-item.e-selected {
  color: var(--att-primary) !important;
}

.sidebar-menu.e-menu-wrapper .e-menu-item.e-separator {
  background-color: var(--att-secondary) !important;
}

.sidebar-menu.e-menu-wrapper .e-menu-item:hover {
  color: var(--att-primary) !important;
  background-color: var(--att-secondary);
}

.sidebar-menu.e-menu-wrapper .e-menu-item * {
  color: white !important;
}

.sidebar-menu.e-menu-wrapper .e-menu-item *:hover {
  color: var(--att-primary) !important;
}

/*.sidebar-menu.e-menu-wrapper .e-menu-text, .sidebar-menu.e-menu-wrapper .e-menu-icon {*/
/*    color: white !important;*/
/*    background-color: var(--att-primary);*/
/*}*/

/*.sidebar-menu.e-menu-wrapper .e-menu-text:hover, .sidebar-menu.e-menu-wrapper .e-menu-icon:hover {*/
/*    color: white !important;*/
/*    background-color: var(--att-primary);*/
/*}*/

.main-menu-header {
  color: white;
  text-align: center;
  font-size: large;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menu-text {
  font-weight: bold;
  text-decoration: underline white;
  display: inline-block;
}

.close-sidebar-btn {
  float: right;
  line-height: var(--line-height);
  font-size: medium;
  margin-right: 15px;
  cursor: pointer;
}

.close-sidebar-btn:before {
  vertical-align: middle;
}
