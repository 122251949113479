.TO_PROCESS {
  background-color: var(--in-progress-status);
}

.FAILED {
  background-color: var(--failed-status);
}

.PROCESSED {
  background-color: var(--passed-status);
}
