@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import './Components/Layout/variables.css';
@import '../node_modules/@syncfusion/ej2-react-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-lists/styles/material.css";

/* import the DropDownButton dependency styles */
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.e-custom,
.e-custom:active,
.e-custom:focus {
  border-radius: 0;
  background-color: var(--att-primary, darkblue) !important;
  color: #fff !important;
}

.e-custom:hover {
  border-radius: 0;
  background-color: var(--att-primary, darkblue);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}

.filters-panel {
  width: var(--page-content-width, 80%) !important;
  margin-bottom: var(--margin-between-panels);
}

.layout-content {
  padding: 40px 20px;
}

.filters-close-btn {
  font-size: small;
  color: var(--att-primary);
  cursor: pointer;
  margin-left: 15px;
  margin-right: 4px;
}

.filters-reset-btn {
  margin-left: auto;
}

.filters-reset-btn {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}

.e-card-header-image {
  width: auto !important;
  height: auto !important;
}

.e-input-group:after,
.e-input-group:before {
  background: var(--att-primary) !important;
}

.inline-flex-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.inline-center-flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .inline-flex-container {
    flex-flow: row wrap;
  }
}

.modal {
  width: 70% !important;
  height: 80%;
  /*padding: 15px;*/
}

.modal img {
  width: 90%;
}

.authenticating-user {
  font-style: italic;
  font-weight: bold;
  font-size: x-large;
  color: var(--att-primary);
  margin-top: 40px;
  text-align: center;
}
