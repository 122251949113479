.test-execution-table-header {
  color: var(--att-primary, darkblue);
}
.field .input {
  color: var(--att-primary, darkblue) !important;
}
.ui.icon.input > i.icon {
  opacity: initial !important;
}
.ui input {
  color: var(--att-primary, darkblue) !important;
}

.test-execution-table-header th {
  vertical-align: top !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--att-primary, darkblue);
  border-color: #007bff;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--att-primary, darkblue);
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.pagination {
  display: flex;
  list-style: none;
  border-radius: 0.25rem;
}

.actions-icon {
  color: var(--att-primary);
  cursor: pointer !important;
  margin-left: 1em !important;
  size: 2rem;
  height: 20px;
  width: 20px;
}

.actions-icon-disabled {
  color: lightgray;
  margin-left: 1em !important;
  size: 2rem;
  height: 20px;
  width: 20px;
}

.test-execution-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: var(--page-content-width);
  margin: auto;
}

.dates-ranges-input {
  align-self: flex-end;
  margin-left: 30px;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background-color: var(--att-primary, darkblue);
}
.e-pager .e-currentitem.e-numericitem.e-focused {
  background-color: var(--att-primary, darkblue) !important;
}

.e-grid .e-filterbarcell .e-input-group.e-input-focus::after,
.e-grid .e-filterbarcell .e-input-group.e-input-focus::before {
  background-color: var(--att-primary, darkblue) !important;
}
.e-grid .e-rowcell {
  padding: 8px 12px !important;
}

.disable-checkbox {
  pointer-events: none;
  opacity: 0.5;
}
.e-filterbarcell {
  padding: 0px 5px !important;
}
.e-tooltip-wrap .e-tip-content {
  font-size: 13px;
  font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif',
    '-apple-system', 'BlinkMacSystemFont';
}

.e-grid .e-headercell {
  padding: 5px;
}

.e-grid .e-headercelldiv {
  padding: 0px !important;
  text-align: center !important;
}

.e-input:focus {
  border-color: var(--att-primary, darkblue) !important;
}
.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: var(--att-primary, darkblue) !important;
}
.att-threedots {
  cursor: pointer;
  border: white !important;
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 0% !important;
}
.att-description {
  cursor: pointer;
  border: white !important;
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 0% !important;
}
.schedule-buttons-container {
  display: flex;
  justify-content: end;
}
