.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #124069 !important;
}
.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background-color: rgba(158, 229, 255, 0.411) !important;
}
