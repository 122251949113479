:root {
  --att-primary: #124069;
  --att-secondary: darkgray;
  --failed-status: #ad1735;
  --failed-status-hover: #780119;
  --passed-status: #869140;
  --passed-status-hover: darkolivegreen;
  --in-progress-status: #5d7a96;
  --in-progress-status-hover: #2d4a66;
  --not-executed-status: darkgray;
  --not-executed-status-hover: gray;
  --page-content-width: 85%;
  --margin-between-panels: 20px;
  --margin-between-cols: 5px;
  --icon-button-font-size: 28px;
  --sidebar-width: 180px;
  --toolbar-height: 50px;
  --line-height: 1.5;
}
