.FAILED {
  background-color: var(--failed-status, red) !important;
  color: white;
}
.FAILED:hover {
  background-color: var(--failed-status-hover, darkred) !important;
  color: white;
}
.PASSED {
  background-color: var(--passed-status, #869140) !important;
  color: white;
}
.PASSED:hover {
  background-color: var(--passed-status-hover, darkolivegreen) !important;
  color: white;
}
.NOT_EXECUTED {
  background-color: var(--not-executed-status) !important;
  color: white;
}
.NOT_EXECUTED button {
  cursor: default !important;
}
.NOT_EXECUTED:hover {
  background-color: var(--not-executed-status-hover) !important;
  color: white;
}

.IN_PROGRESS {
  background-color: var(--in-progress-status) !important;
  color: white;
}

.IN_PROGRESS:hover {
  background-color: var(--in-progress-status-hover) !important;
  color: white;
}

.CANCELLED {
  background-color: var(--not-executed-status) !important;
  color: white;
}
.CANCELLED button {
  cursor: default !important;
}
.CANCELLED:hover {
  background-color: var(--not-executed-status-hover) !important;
  color: white;
}

.no-screenshots-failed {
  background-color: var(--failed-status, red) !important;
  color: white;
}
.no-screenshots-failed button {
  cursor: default !important;
}
.no-screenshots-passed {
  background-color: #869140 !important;
  color: white;
}

.no-screenshots-passed button {
  cursor: default !important;
}
.noPadding {
  padding: 0% !important;
}
.resultToggle {
  width: 100% !important;
  text-align: start !important;
  padding: 0% !important;
}
.accordionCollapse {
  /* NO BORRAR!! */
}

.carousel-indicators li {
  background-color: darkgray !important;
}

.test-result-accordion {
  width: var(--page-content-width, 80%);
}

.test-results-actions-panel {
  display: flex;
  flex-flow: row nowrap;
  width: var(--page-content-width, 80%);
  margin-bottom: var(--margin-between-panels, 15px);
  gap: 15px;
}

.test-results-filter-panel {
  width: var(--page-content-width, 80%) !important;
  margin-bottom: 15px;
}
.e-card-header-image.e-icons.e-filter-clear {
  font-size: var(--icon-button-font-size, 28px);
  color: var(--att-primary, steelblue) !important;
  cursor: pointer;
}
.clear-filter-btn {
  text-align: right;
}
.test-result-header {
  display: flex;
  padding: 0% !important;
}
.test-result-cmp-header {
  display: flex;
  padding: 0% !important;
}

.test-result-actions {
  font-weight: bold;
  width: 20%;
  justify-content: flex-end;
  display: flex;
}
.test-result-actions div {
  padding: 0%;
}

.test-result-components-actions {
  font-weight: bold;
  width: 20%;
  justify-content: flex-end;
  display: flex;
}
.test-result-components-actions div {
  padding: 0%;
}

.accordion-tab
  .e-accordion
  .e-acrdn-item
  .e-acrdn-header
  .e-toggle-icon
  .e-icons {
  display: none !important;
  border-radius: 3px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0%;
  border-radius: 3px;
}

.e-accordion
  .e-acrdn-item
  .e-acrdn-panel.e-nested
  > .e-acrdn-content
  .e-accordion
  .e-acrdn-panel.e-nested
  .e-acrdn-content
  .e-acrdn-header {
  padding: 0%;
  border-radius: 3px;
}

.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  padding: 0%;
}

@media (max-width: 1570px) {
  .test-result-header {
    flex-direction: column;
  }
  .test-result-actions {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .test-result-actions {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .test-result-components-actions {
    width: 100%;
    margin-top: 10px;
    text-align: start;
  }
  .test-result-accordion {
    width: 100%;
  }
  .test-result-filter-panel {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .test-result-filter-panel {
    flex-direction: column;
  }

  .test-results-actions-panel {
    flex-direction: column;
  }
}

.e-acrdn-content {
  padding: 15px !important;
}

.e-acrdn-content .content-message {
  color: red;
  font-size: medium;
}

.accordion-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.accordion-container > div {
  width: var(--page-content-width) !important;
}

/*Tree view*/
.accordiontree .e-list-item.e-level- .e-icons.e-icon-collapsible,
.accordiontree .e-list-item.e-level-1 .e-icons.e-icon-collapsible,
.accordiontree .e-list-item.e-level-1 .e-icon-expandable {
  display: none;
  width: 0;
}

.accordion-container .e-treeview .e-list-item.e-active > .e-fullrow,
.accordion-container .e-treeview .e-list-item.e-active > .e-fullrow:hover {
  background-color: white !important;
  border-color: white !important;
}

.accordion-container .e-treeview .e-list-item.e-hover .e-fullrow {
  background-color: white !important;
  border-color: white !important;
}

.accordion-container .e-treeview .e-list-item {
  padding: 0 !important;
}

.accordion-container .e-treeview .e-list-item .e-fullrow {
  display: none;
}

.accordion-container .e-treeview > ul {
  padding: 0 !important;
}

.accordion-container .e-treeview .e-list-item .e-icon-expandable {
  display: none;
}

.accordion-container .e-treeview .e-list-item .e-icon-wrapper {
  padding: 0 !important;
}

.accordion-container .e-treeview .e-list-item .e-list-text {
  padding: 0 !important;
  width: 100%;
}

.accordion-container .e-treeview .e-list-item .e-ul {
  margin: 3px 0;
}

.accordion-container .e-treeview .content-message {
  color: red;
  font-size: medium;
  font-style: italic;
  white-space: pre-line;
}

.accordion-container
  .e-treeview
  .e-list-item
  .e-ul.e-list-parent
  .e-text-content {
  padding: 0;
}

.in-progress-te-message {
  font-style: italic;
  font-weight: bold;
  font-size: x-large;
  color: var(--att-primary);
  margin-top: 40px;
  text-align: center;
}

.e-treeview .e-text-content {
  padding: 0px !important;
}

.accordion-schedule
  .e-accordion
  .e-acrdn-item.e-select.e-selected.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-icon,
.e-accordion
  .e-acrdn-item.e-select.e-selected.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-content,
.e-accordion
  .e-acrdn-item.e-select.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-icon,
.e-accordion
  .e-acrdn-item.e-select.e-expand-state
  > .e-acrdn-header
  .e-acrdn-header-content {
  color: black !important;
}

.accordion-schedule
  .e-accordion
  .e-acrdn-item.e-select.e-selected.e-expand-state
  > .e-acrdn-header
  > .e-toggle-icon,
.e-accordion
  .e-acrdn-item.e-select.e-expand-state
  > .e-acrdn-header
  > .e-toggle-icon {
  color: black !important;
}

.e-accordion
  .e-acrdn-item
  .e-acrdn-header
  .e-toggle-icon
  .e-tgl-collapse-icon.e-icons {
  color: black !important;
}
