.test-result-header-container {
  min-height: 40px !important;
  font-size: medium !important;
  justify-content: end;
  gap: 10px;
  padding: 5px 15px !important;
}

.test-results-header-text {
  font-size: medium;
  color: white;
  margin-right: auto;
  white-space: break-spaces;
}

.extra-actions-container > * {
  font-size: large;
  padding: 6px;
}
