@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";

.att-copy-button{
    cursor: pointer;
  border:white !important;
  background-color:rgba(0,0,0, 0) !important;
}
.e-tooltip-wrap.e-popup {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 2px;
}
