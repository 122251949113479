@import url("https://fonts.googleapis.com/css?family=Dosis:300,400,700,800");

/** Styles for the 403 Page **/

.particle-error,
.permission_denied,
#particles-js {
  width: 100%;
  height: 100%;
  margin: 0px !important;
}

#particles-js {
  position: fixed !important;
  opacity: 0.23;
}

.permission_denied {
  background: #fff !important;
}

.permission_denied a {
  text-decoration: none;
}

.denied__wrapper {
  max-width: 390px;
  width: 100%;
  height: 390px;
  display: block;
  margin: 0 auto;
  position: relative;
  margin-top: 8vh;
}

.permission_denied h1 {
  text-align: center;
  color: #124069;
  font-family: "Dosis", sans-serif;
  font-size: 100px;
  margin-bottom: 0px;
  font-weight: 800;
}

.permission_denied h3 {
  text-align: center;
  color: gray;
  font-size: 19px;
  line-height: 23px;
  max-width: 330px;
  margin: 0px auto 30px auto;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}

.permission_denied h3 span {
  position: relative;
  width: 65px;
  display: inline-block;
}

.permission_denied h3 span:after {
  content: "";
  border-bottom: 3px solid #ffbb39;
  position: absolute;
  left: 0;
  top: 43%;
  width: 100%;
}

.denied__link {
  background: none;
  color: #fff;
  padding: 12px 0px 10px 0px;
  border: 1px solid #fff;
  outline: none;
  border-radius: 7px;
  width: 150px;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
  display: block;
  margin-bottom: 40px;
  margin-top: 25px;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}

.denied__link:hover {
  color: #ffbb39;
  border-color: #ffbb39;
  cursor: pointer;
  opacity: 1;
}

.permission_denied .stars {
  animation: sparkle 1.6s infinite ease-in-out alternate;
}

@keyframes sparkle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

#astronaut {
  width: 43px;
  position: absolute;
  right: 20px;
  top: 210px;
  animation: spin 4.5s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 600px) {
  .permission_denied h1 {
    font-size: 75px;
  }
  .permission_denied h3 {
    font-size: 16px;
    width: 200px;
    margin: 0 auto;
    line-height: 23px;
  }
  .permission_denied h3 span {
    width: 60px;
  }
  #astronaut {
    width: 35px;
    right: 40px;
    top: 170px;
  }
}

.saturn,
.saturn-2,
.hover {
  animation: hover 2s infinite ease-in-out alternate;
}

@keyframes hover {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
.st0{fill:none;} 
.st1{fill:#504E55;}
.st2{fill:#124069;}
.st3{fill:#FFFFFF;} 
.st4{opacity:0.24;} 
.st5{fill:#77574E;} 
.st6{fill:#124069;} 
.st7{fill:#ECECEC;} 
.st8{fill:#F4A89C;} 
.st9{fill:#CFC9E5;} 
.st10{opacity:0.28;} 
.st11{opacity:0.25;} 
.st12{fill:#6F635C;} 
.st13{fill:#DAE7BE;} 
.st14{fill:#FFE0A6;} 
.st15{fill:#5F5E60;} 
.st16{fill:#CFE1AF;} 
.st17{fill:#EBE9F5;} 
.st18{fill:#53515A;} 
.st19{opacity:0.42;} 
.st20{fill:#53515B;}