.e-float-input textarea,
.e-float-input.e-control-wrapper textarea {
  border: 1px solid darkgray !important;
  border-radius: 3px;
  height: 200px !important;
}
.att-buttons-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.att-scope-definition{
  margin-top: 20px;
}
@media screen and (min-width: 500px) {
  .att-buttons-container{
    flex-direction: row;
    gap: 2px;
  }
}
  
@media screen and (min-width: 768px) {
  .att-scope-definition{
    margin-top: 0px;
  }
}