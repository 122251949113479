.e-tooltip-wrap.e-popup {
  background-color: white;
  opacity: 1;
  /* width: 200px !important; */
  /* height: 100px !important; */
  padding: 15px;
}

.e-tooltip-wrap .e-tip-content {
  color: black;
  font-size: medium;
}

.extra-files-popover {
  display: flex;
  flex-flow: column;
  justify-content: left;
  gap: 15px;
}

.extra-files-popover-title {
  font-weight: bold;
}

/*.extra-files-container > * {*/
/*  padding: 6px;*/
/*}*/

.extra-files-container {
  gap: 10px;
  align-items: center;
}

.extra-files-container .e-tooltip {
  /*line-height: initial;*/
}

.extra-files-container .e-tooltip > span:before {
  vertical-align: middle;
}

.extra-files-container span {
  font-size: large;
}
