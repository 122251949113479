.toolbar-nav {
  display: flex;
  padding: 10px;
  height: var(--toolbar-height);
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .toolbar-nav-items {
    display: none !important;
  }
}

.nav-links {
  color: var(--att-primary);
  font-weight: bold;
  font-style: italic;
}

.toolbar-menu-container {
  margin-left: auto;
}

.toolbar-nav-items {
  display: flex;
  /*margin-left: auto;*/
  column-gap: 20px;
}

.e-dropdown-btn {
  color: #124069;
}

/* .e-dropdown-popup ul .e-item .e-menu-url {
  color: #124069 !important;
} */
.e-dropdown-popup ul .e-item:hover .e-menu-url {
  color: white !important;
  background-color: #124069;
}
