.custom-progress-bar-container {
  width: 50%;
}
.custom-progress-bar-info-panel {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: var(--margin-between-panels);
  margin-bottom: 30px;
}

.custom-progress-bar-info-panel > div {
  justify-content: center;
  display: flex;
  flex-flow: column;
  gap: 15px;
  padding: 10px;
}

.status-value {
  font-weight: bold;
  font-size: xx-large;
}

.status-text {
  font-weight: bold;
  font-size: medium;
}

.e-badge {
  width: 100px;
}
.e-badge-dark:hover {
  background-color: black !important;
}
.consecutive-fails {
  background-color: burlywood;
}

@media (max-width: 1220px) {
  .custom-progress-bar-container {
    width: 60%;
  }
}

.progress-custom {
  height: 1.5rem !important;
  border-radius: 10px !important;
  /* border: 2px solid black; */
  /* background-color: white !important; */
}
.progress-custom div {
  background-color: var(--att-primary, darkblue) !important;
  border-radius: 7px !important;
}

@media (max-width: 976px) {
  .custom-progress-bar-container {
    width: 75%;
  }

  /*.custom-progress-bar-info-panel {*/
  /*  flex-direction: column;*/
  /*  justify-content: start;*/
  /*  width: auto;*/
  /*}*/
  /*.custom-progress-bar-info-panel > div {*/
  /*  flex-direction: column;*/
  /*  justify-content: start;*/
  /*  width: auto;*/
  /*}*/
}

@media (max-width: 678px) {
  .custom-progress-bar-info-panel {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    gap: 10px;
  }

  .custom-progress-bar-info-panel > div {
    width: 60%;
    gap: 10px;
  }

  .status-text {
    font-weight: normal;
  }

  .status-value {
    font-size: large;
  }
}
