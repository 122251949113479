.status-badges-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.status-badges-container > span:first-child {
  font-weight: bold;
}

.status-badges-container > span {
  font-size: medium;
}

.passed-badge {
  background-color: #28a745;
}

.failed-badge {
  background-color: #dc3545;
}
